import { DownOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CLIENT_NAME, EMAIL_OPT_IN } from "constants/widgetBuilder";
import WidgetBodyContext from "contexts/WidgetBody";
import _, { debounce as _debounce } from "lodash";
import PropTypes from "prop-types";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUrlS3 } from "services/funcHelper";
import { getBrowserGeolocation } from "services/location";
import {
  checkedAllTerms,
  creatorOptInEmailValues,
  formatCustomProperties,
  getIPStack,
  getSocialUsernameFromURL,
  getTermsIds,
  handleSetAttributePlaceholder,
  setClientId,
  setFieldRules,
  setPayloadData,
} from "services/widgetBuilder";
import {
  DEFAULT_FIELD_NAME,
  DEFAULT_TIMEOUT,
  FAILED,
  FIELD_NAME,
  HEIGHT_DRAG_AND_DROP,
  LOCATION_COLLECT_METHOD,
  MAILING_ADDRESS_FIELD_NAME,
  MEDIA_TYPE,
  REDUNDANT,
  REUSED_FIELD_NAME,
  SELECTION_TYPE,
  SUCCEEDED,
  SUPPORTED_FILE,
  TRIMMED_FIELD_NAME,
  TYPE_CUSTOM_FIELD,
  TYPE_TERM_CONDITION,
  UPLOADER_TYPE,
} from "../../../constants/common";
import configUrls from "../../../constants/configUrls";
import errorMsgs from "../../../constants/errorMsgs";
import THEME from "../../../constants/themes";
import WidgetBuilderContext from "../../../contexts/WidgetBuilder";
import utils from "../../../services/utils";
import {
  createContentRequest,
  getGeoLocationRequest,
  getLocationEnd,
  getLocationRequest,
  storeData,
  uploadGoogleDriveContentRequest,
  uploadInstagramContentRequest,
  uploadYouTubeContentRequest,
  validateCampaignRequest,
} from "../../../stores/actions";
import {
  StyledForm,
  StyledIcon,
} from "../../../styled-components/Common/CommonStyled";
import {
  StyledError,
  StyledFormContainer,
  StyledFormItemCustomWidget as StyledFormItem,
  StyledGoogleTranslate,
  StyledInnerDropzone,
  StyledInputItem,
  StyledLink,
  StyledListItem,
  StyledMessage,
  StyledSelect,
  StyledSubmitButton,
  StyledText,
  StyledTextAreaItem,
  StyledTitle,
  StyledUpload,
  StyledUploadContainer,
  StyledWrapperSession,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";
import MailingAddress from "./MailingAddress";
import TermConditions from "./TermConditions";
import Terms from "./Terms";
import WidgetUploadSource from "./WidgetUploadSource";

const WidgetBody = (props) => {
  const { t } = useTranslation();
  const refListField = useRef({});
  const uploadTabRef = useRef();
  const [form] = useForm();

  const {
    detailWidget,
    isMoreField = false,
    setIsMoreField,
    globalProps,
    handleFormState,
  } = props;
  const { requestAPI, setIsShowLoading } = globalProps;
  const {
    createContentStatus,
    createdContentError,
    getLocationStatus,
    validateCampaignStatus,
    campaign: storedCampaign,
    uploadLocation,
  } = useSelector((state) => state.widgets);

  const {
    clientDetail = {},
    isCancelledProgressModal = false,
    fileProgress = [],
    uploadedContentList = [],
    instagramAccount,
  } = useSelector((state) => state.widgets);
  const { brandName = "", orgType = "" } = clientDetail || {};
  const { youTubeAccount } = useSelector((state) => state.googleStore);
  const pathLocation = useLocation();
  const { search } = pathLocation;
  const {
    selectedVideoList,
    activeUploaderType,
    setActiveUploaderType,
    setUploadPayloadList,
    setUploadInfo,
    uploadInfo,
    isMobile,
    className: scriptTagClassName,
    tikTokUser,
    googleDrive,
    errorMsg,
    setErrorMsg,
    handleErrorMsg,
    terms,
    setTerms,
    widgetInfo,
  } = useContext(WidgetBuilderContext);

  const dispatch = useDispatch();

  const challengeId = utils.getChallengeId(search); // campaignId
  const selectId = utils.getSelectId(search);

  const {
    metadata,
    clientId,
    id: widgetId,
    widgetSettingsDTO,
    terms: termsTemplate = [],
    termsTemplateId,
  } = detailWidget || {};
  const { header, body, colors } = metadata;
  const { title, message } = header;
  const { basicFields, emailOptIn = {} } = body || {};
  let { listFields } = body;

  const {
    highlight,
    bgFileDropZone,
    bgSubmit,
    colorUploadIcon = THEME.colors.orangeBase,
    buttonUrl,
  } = colors;

  const { name: emailOptInType = "", fieldValues = {} } = emailOptIn || {};
  const replacedFieldValues = {
    ...fieldValues,
    hyperlinkText: fieldValues.textLink || "",
    isTermsMandated: fieldValues.required || false,
    agreementMessage: `${
      fieldValues?.text?.replace(CLIENT_NAME, brandName) || ""
    } `,
    hyperlinkUrl: fieldValues.url || "",
    displayType: TYPE_TERM_CONDITION.link,
  };

  const { timeoutDuration, locationCollectMethod } = widgetSettingsDTO;

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [activeTerms, setActiveTerms] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [campaign, setCampaign] = useState({}); //Campaign
  const [uploadedFileType, setUploadedFileType] = useState([]);
  const [className, setClassName] = useState("dropzone");

  const isExistUploadLocation = uploadLocation && !_.isEmpty(uploadLocation);

  const listFieldInput = useMemo(() => {
    return [
      TYPE_CUSTOM_FIELD.email,
      TYPE_CUSTOM_FIELD.phone,
      TYPE_CUSTOM_FIELD.instagram,
      TYPE_CUSTOM_FIELD.twitter,
      TYPE_CUSTOM_FIELD.tiktok,
      TYPE_CUSTOM_FIELD.shortAnswer,
    ];
  }, []);

  listFields = useMemo(() => {
    return listFields.map((field) => {
      const { type } = field;

      switch (true) {
        case type === DEFAULT_FIELD_NAME.instagram:
          return { ...field, fieldName: FIELD_NAME.instagram };

        case type === DEFAULT_FIELD_NAME.tiktok:
          return { ...field, fieldName: FIELD_NAME.tiktok };

        case type === DEFAULT_FIELD_NAME.twitter:
          return { ...field, fieldName: FIELD_NAME.twitter };

        case type === DEFAULT_FIELD_NAME.youtube:
          return { ...field, fieldName: FIELD_NAME.youtube };

        case type === DEFAULT_FIELD_NAME.phone:
          return { ...field, fieldName: type };

        default:
          return { ...field };
      }
    });
  }, [listFields]);

  // Reset after focusing to action by pressing TAB
  const resetTerms = () => {
    const existTermFocused = terms.some((item) => item.isFocusing);
    if (existTermFocused) {
      const termsTemp = terms.map((item) => {
        if (item.isFocusing) item = { ...item, isFocusing: false };
        return item;
      });

      setTerms([...termsTemp]);
    }
  };

  useEffect(() => {
    if (challengeId) {
      dispatch(
        validateCampaignRequest({
          clientId,
          campaignId: challengeId,
        })
      );
    }
  }, []);

  useEffect(() => {
    utils.googleTranslateScript();
  }, [isMobile]);

  useEffect(() => {
    let classNameTemp = className;
    const hasClassNameVideo = className.includes("video");
    if (
      uploadedFiles.length > 0 &&
      uploadedFiles[0].type.includes("video") &&
      !hasClassNameVideo
    ) {
      classNameTemp = `${classNameTemp} video`;
    } else {
      if (hasClassNameVideo) {
        classNameTemp = classNameTemp.replace(" video", "");
      }
    }

    setClassName(classNameTemp);
  }, [uploadedFiles]);

  useEffect(() => {
    initTerms();
  }, [detailWidget]);

  useEffect(() => {
    if (!isMobile && refListField.current) {
      const compareHeight =
        refListField.current.clientHeight - REDUNDANT >
        HEIGHT_DRAG_AND_DROP.desktop;

      if (isMoreField !== compareHeight) {
        setIsMoreField(compareHeight);
      }
    }

    if (refListField.current) {
      handleSetAttributePlaceholder([...basicFields, ...listFields], t);
    }
  }, [refListField.current, isMobile, listFields, basicFields]);

  useEffect(() => {
    if (createContentStatus === FAILED && createdContentError) {
      setIsShowLoading(false);
      handleErrorMsg(createdContentError);
    }
  }, [createContentStatus, createdContentError]);

  useEffect(() => {
    if (uploadTabRef.current) {
      window.addEventListener("keyup", handleFocusUploadBox);
      window.addEventListener("click", handleClickOutside);

      return () => {
        window.removeEventListener("keyup", handleFocusUploadBox);
        window.removeEventListener("click", handleClickOutside);
      };
    }
  }, [uploadTabRef.current]);

  useEffect(() => {
    if (getLocationStatus === SUCCEEDED) {
      form.submit();
    }
    if (getLocationStatus === SUCCEEDED || getLocationStatus === FAILED) {
      dispatch(getLocationEnd());
    }
  }, [getLocationStatus]);

  useEffect(() => {
    if (validateCampaignStatus === SUCCEEDED && storedCampaign) {
      setCampaign(utils.parseChallengeData(storedCampaign));
    }
  }, [validateCampaignStatus, storedCampaign]);

  const handleFocusUploadBox = (e) => {
    if (uploadTabRef.current.contains(e.target)) {
      setClassName(`${className} focus`);
    }
  };

  const handleClickOutside = (e) => {
    if (!uploadTabRef.current.contains(e.target)) {
      if (className.includes("focus")) {
        const classNameTemp = className.replace(" focus", "");
        setClassName(classNameTemp);
      }
    }
  };

  const socialUsernameFromURL = useMemo(() => {
    const socialUsernameTemp = getSocialUsernameFromURL(search);
    return utils.removeEmptyValueObj(socialUsernameTemp);
  }, [activeUploaderType]);

  const renderTitle = <StyledTitle isMobile={isMobile}>{title}</StyledTitle>;
  const renderMessage = (
    <StyledMessage
      isMobile={isMobile}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );

  const onDrop = async (fileUploads) => {
    fileUploads = await utils.convertFileUploads(fileUploads, setIsShowLoading);
    await validateUploadedFilesCustom(fileUploads)
      .then((result) => {
        if (result) {
          setUploadedFiles(fileUploads);
          setUploadInfo((prev) => ({ ...prev, fileUploads }));
        } else {
          setUploadedFiles([]);
        }
      })
      .catch((error) => console.log("Error", error));

    if (!activeUploaderType || activeUploaderType !== UPLOADER_TYPE.UPLOAD) {
      setActiveUploaderType(UPLOADER_TYPE.UPLOAD);
    }
  };

  const validateUploadedFilesCustom = async (files) => {
    const { errorMsg, isValidFiles } = await utils.validateUploadedFiles(
      files,
      "en",
      widgetSettingsDTO
    );

    if (!isValidFiles) {
      setUploadedFiles([]);
      handleErrorMsg(errorMsg);
      return false;
    }

    setUploadedFiles(files);
    clearMessage();
    return true;
  };

  const initTerms = () => {
    let termsTemp = [];
    const termsConditionsTemp = termsTemplate ? [...termsTemplate] : [];

    if (
      emailOptIn &&
      emailOptIn.fieldValues &&
      emailOptInType === EMAIL_OPT_IN.ASK_USER.name &&
      emailOptIn.fieldValues.fieldName === EMAIL_OPT_IN.FIELD_NAME
    ) {
      termsConditionsTemp.push(replacedFieldValues);
    }

    termsTemp = termsConditionsTemp.map((item) => {
      return {
        ...item,
        required: item.isTermsMandated || false,
        checked: false,
        isAgreed: false,
      };
    });

    setTerms([...termsTemp]);
  };

  const clearMessage = () => {
    setErrorMsg("");
  };

  const renderUpload = () => {
    return (
      <StyledUpload
        style={{ opacity: 0 }}
        tabIndex={-1}
        bgColor={bgFileDropZone}
        isMobile={isMobile}
      >
        <StyledUploadContainer className="preview-img">
          <Dropzone
            onDrop={(files) => onDrop(files)}
            accept={SUPPORTED_FILE.ACCEPTED}
          >
            {({ getRootProps, getInputProps }) => (
              <StyledInnerDropzone
                id="dropzone-upload"
                {...getRootProps()}
                className={className}
                color={colorUploadIcon}
              >
                <input {...getInputProps()} />
              </StyledInnerDropzone>
            )}
          </Dropzone>
        </StyledUploadContainer>
      </StyledUpload>
    );
  };

  const renderOptions = (options) => {
    return options?.map((option) => ({ label: option, value: option }));
  };

  const onBlurFormItem = (event, item) => {
    const value = event.target.value;
    const hasSpaces =
      typeof value === "string" &&
      (value.endsWith(" ") || value.startsWith(" "));
    if (hasSpaces) {
      form.setFieldsValue({
        [item.fieldName]: value.trim(),
      });
    }
  };

  const renderListField = () => {
    return [...basicFields, ...listFields].map((item, index) => {
      const key = `${index}_${item.type}`;
      const otherProps = {};
      if (TRIMMED_FIELD_NAME.includes(item.fieldName)) {
        otherProps.normalize = (value) => value.trim();
      } else {
        otherProps.onBlur = (event) => onBlurFormItem(event, item);
      }

      if (listFieldInput.includes(item.type)) {
        return (
          <StyledFormItem
            isMobile={isMobile}
            key={key}
            name={item.fieldName}
            rules={setFieldRules(item, t)}
            {...otherProps}
          >
            <StyledInputItem
              isMobile={isMobile}
              alt={`${t(
                "widget_builder.placeholder.short_answer_form_field"
              )}: ${item.name}`}
              placeholder={`${item.name}${item.required ? "*" : ""}`}
            />
          </StyledFormItem>
        );
      }

      if (item.type === TYPE_CUSTOM_FIELD.paragraph) {
        return (
          <StyledFormItem
            isMobile={isMobile}
            key={key}
            name={item.fieldName}
            rules={setFieldRules(item, t)}
            className={TYPE_CUSTOM_FIELD.paragraph}
            {...otherProps}
          >
            <StyledTextAreaItem
              rows={3}
              autoSize={{ minRows: 3, maxRows: 3 }}
              isMobile={isMobile}
              alt={`${t(
                "widget_builder.placeholder.short_answer_form_field"
              )}: ${item.name}`}
              placeholder={`${item.name}${item.required ? "*" : ""}`}
              aria-label={`${item.name}${item.required ? "*" : ""}`}
            />
          </StyledFormItem>
        );
      }

      if (item.type === TYPE_CUSTOM_FIELD.dropdown) {
        const selectProps = {};

        if (item?.answerType === SELECTION_TYPE.MULTIPLE) {
          selectProps.mode = SELECTION_TYPE.MULTIPLE.toLowerCase();
          selectProps.allowClear = true;
          selectProps.maxTagCount = 5;
        }

        return (
          <StyledFormItem
            isMobile={isMobile}
            key={key}
            name={item.fieldName}
            rules={setFieldRules(item, t)}
          >
            <StyledSelect
              isMobile={isMobile}
              width="100%"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder={`${item.name}${item.required ? "*" : ""}`}
              virtual={false}
              className="dropdown-select_detail_widget"
              optionFilterProp="label"
              showSearch
              options={renderOptions(item.listOption)}
              {...selectProps}
            />
          </StyledFormItem>
        );
      }

      if (item.type === TYPE_CUSTOM_FIELD.mailAddress) {
        return (
          <MailingAddress
            key={key}
            uploadInfo={uploadInfo}
            setFieldsValue={setFieldsValue}
            setUploadInfo={setUploadInfo}
            mailingAddress={item}
          />
        );
      }

      return null;
    });
  };

  const handleLocationCollect = () => {
    const successCallback = (position) => {
      if (position) {
        dispatch(getGeoLocationRequest(position));
      }
    };
    const onSubmit = () => {
      form.submit();
    };

    if (locationCollectMethod !== LOCATION_COLLECT_METHOD.NO_COLLECT) {
      if (
        locationCollectMethod === LOCATION_COLLECT_METHOD.COLLECT_WITH_CONSENT
      ) {
        getBrowserGeolocation(
          (position) =>
            isExistUploadLocation ? onSubmit() : successCallback(position),
          onSubmit
        );
      } else if (
        locationCollectMethod ===
        LOCATION_COLLECT_METHOD.COLLECT_WITHOUT_CONSENT
      ) {
        isExistUploadLocation ? onSubmit() : dispatch(getLocationRequest());
      }
    } else {
      onSubmit();
    }
    setIsSubmitting(false);
  };

  const handleSubmitButton = () => {
    // To ensure clientId always exists in session storage before uploading
    setClientId(clientDetail);

    if (
      !selectedVideoList.length &&
      !uploadedFiles.length &&
      !googleDrive?.selectedMediaList.length
    ) {
      handleErrorMsg(t("message.no_file_attached"));
    } else {
      if (checkedAllTerms(terms)) {
        form
          .validateFields()
          .then(() => {
            handleLocationCollect();
          })
          .catch(() => {});
      } else {
        setIsSubmitting(true);
      }
    }
  };

  const renderSubmitButton = (
    <StyledSubmitButton
      className={buttonUrl ? "has_url" : ""}
      backgroundUrl={getUrlS3(buttonUrl || "")}
      onClick={handleSubmitButton}
      backgroundColor={bgSubmit}
      highlight={highlight}
      isMobile={isMobile}
    >
      {t("button.submit")}
    </StyledSubmitButton>
  );

  const renderGoogleTranslate = () => {
    return (
      <StyledGoogleTranslate
        isMobile={isMobile}
        id="google_translate_element"
        style={{ minHeight: "60px" }}
      >
        <StyledIcon color="rgba(0, 0, 0, 0.25)">
          <DownOutlined style={{ fontSize: "13px" }} />
        </StyledIcon>
      </StyledGoogleTranslate>
    );
  };

  const renderDropzone = (isMobile) => {
    return (
      <StyledFormItem
        style={{ display: "none" }}
        className="upload-file"
        isMobile={isMobile}
        name="fileUpload"
      >
        {renderUpload()}
      </StyledFormItem>
    );
  };

  const handleTerms = (checked, params) => {
    const { isTermsMandated } = params || {};

    let value = {
      required: !!isTermsMandated,
      checked,
    };
    const termsTemp = updateTerms(terms, params.id, value);
    const isCheckedAll = termsTemp
      .filter((item) => item.required)
      .every((item) => item.checked);
    if (isCheckedAll) {
      setIsSubmitting(false);
    }
    setTerms([...termsTemp]);
  };

  const renderTerms = useMemo(() => {
    const props = {
      terms,
      handleTerms,
      highlight,
      setActiveTerms,
      isSubmitting,
    };

    return <Terms {...props} />;
  }, [terms, isSubmitting]);

  const renderEmailOptText = () => {
    return (
      emailOptInType === EMAIL_OPT_IN.OPT_IN_SUBMISSION.name && (
        <StyledText
          margin="24px 0 0 0"
          style={{ fontSize: "10px" }}
          align="unset"
          color={THEME.colors.gray1}
        >
          {replacedFieldValues?.agreementMessage}
          <StyledLink
            href={replacedFieldValues?.hyperlinkUrl}
            fontSize="10px"
            color={highlight}
            target="_blank"
          >
            {replacedFieldValues?.hyperlinkText}
          </StyledLink>
        </StyledText>
      )
    );
  };

  const setFieldsValue = (values) => {
    form.setFieldsValue({ ...values });
  };

  const renderDesktopBody = () => {
    return (
      <StyledFormContainer>
        {(title || message) && !isMoreField && (
          <StyledWrapperSession marginTop="32px">
            {title && renderTitle}
            {message && renderMessage}
          </StyledWrapperSession>
        )}

        <Row gutter={[32, 32]} style={{ marginTop: "32px" }}>
          <Col span={12}>
            {isMoreField && (
              <StyledWrapperSession marginBottom="22px">
                {title && renderTitle}
                {message && renderMessage}
              </StyledWrapperSession>
            )}
            <StyledWrapperSession
              marginBottom="24px"
              onFocus={() => {
                resetTerms();
              }}
            >
              {renderDropzone()}
              <WidgetUploadSource onDrop={onDrop} />
            </StyledWrapperSession>

            {renderTerms}
          </Col>
          <Col span={12}>
            <StyledListItem
              onFocus={() => {
                resetTerms();
              }}
              ref={refListField}
            >
              {renderListField()}
            </StyledListItem>
            {renderSubmitButton}
            {renderEmailOptText()}
            {errorMsg && (
              <StyledError
                isMobile={isMobile}
                margin="12px 0"
                color={THEME.colors.redBase}
                dangerouslySetInnerHTML={{ __html: errorMsg }}
              />
            )}
            {renderGoogleTranslate()}
          </Col>
        </Row>
      </StyledFormContainer>
    );
  };

  const renderMobileBody = () => {
    return (
      <StyledFormContainer>
        {(title || message) && (
          <StyledWrapperSession marginTop="32px">
            {title && renderTitle}
            {message && renderMessage}
          </StyledWrapperSession>
        )}

        <StyledWrapperSession marginTop="32px">
          {renderListField()}
          {renderDropzone(true)}
          <StyledWrapperSession marginBottom="24px">
            <WidgetUploadSource />
          </StyledWrapperSession>
          {renderTerms}
          {renderSubmitButton}
          {renderEmailOptText()}
          {errorMsg && (
            <StyledError
              margin="12px 0"
              isMobile={isMobile}
              color={THEME.colors.redBase}
              dangerouslySetInnerHTML={{ __html: errorMsg }}
            />
          )}
          {renderGoogleTranslate()}
        </StyledWrapperSession>
      </StyledFormContainer>
    );
  };

  const handleAgreeTerm = (id) => {
    const termsTemp = updateTerms(terms, id, {
      isAgreed: true,
      checked: true,
    });
    setTerms([...termsTemp]);
    setIsSubmitting(false);
    setActiveTerms();
  };

  const updateTerms = (data, id, updatedValue) => {
    let dataTemp = [...data];

    const termIndex = dataTemp.findIndex((item) => item.id === id);
    if (termIndex > -1) {
      dataTemp[termIndex] = {
        ...dataTemp[termIndex],
        ...updatedValue,
      };
    }

    dataTemp = dataTemp.map((item) => {
      if (item.id === id) {
        item = { ...item, isFocusing: true };
      } else {
        item = { ...item, isFocusing: false };
      }
      return item;
    });

    return dataTemp;
  };

  const handleDebounceFn = (email) => {
    getCreatorAPI(email);
  };

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  const onValuesChange = (changedValue) => {
    const fieldName = Object.keys(changedValue)[0];
    if (fieldName.includes(DEFAULT_FIELD_NAME.email)) {
      const { emailAddress } = changedValue;
      const email = emailAddress.trim();

      if (email && utils.validateEmail(email)) {
        debounceFn(email);
      }
    }

    setUploadInfo((prev) => ({ ...prev, ...changedValue }));
  };

  const getCreatorAPI = (email) => {
    setErrorMsg(null);
    const successCallback = (resp) => {
      let basicInfo = { ...resp } || {};
      const {
        email,
        firstName = "",
        lastName = "",
        instagramUsername = "",
        twitterUsername = "",
        tiktokUsername = "",
        youtubeUsername = "",
      } = basicInfo || {};

      const setFieldValue = {
        first_Name: firstName,
        last_Name: lastName,
      };

      let existValue = {
        email,
      };

      basicFields.forEach((item) => {
        const { fieldName } = item;
        if (item.fieldName in setFieldValue) {
          existValue = {
            ...existValue,
            [item.fieldName]: setFieldValue[fieldName],
          };
        }
      });

      listFields.forEach((field) => {
        const { type, fieldName } = field;

        switch (true) {
          case type === DEFAULT_FIELD_NAME.instagram:
            existValue[fieldName] = instagramUsername;
            break;

          case type === DEFAULT_FIELD_NAME.twitter:
            existValue[fieldName] = twitterUsername;
            break;

          case type === DEFAULT_FIELD_NAME.tiktok:
            existValue[fieldName] = tiktokUsername;
            break;

          case type === DEFAULT_FIELD_NAME.youtube:
            existValue[fieldName] = youtubeUsername;
            break;

          default:
            break;
        }
      });

      form.setFieldsValue(existValue);
      setUploadInfo((prev) => ({
        ...prev,
        ...existValue,
      }));
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error
        ? resp.error
        : resp.status
        ? resp.status.status
        : errorMsgs["en"].callAPIFailed;

      handleErrorMsg(errorMsg);
    };

    const url = `${configUrls.API.getCreator}?clientId=${clientId}&email=${email}`;
    requestAPI("get", url, {}, successCallback, failedCallback);
  };

  const generateURL = (data) => {
    let url =
      activeUploaderType === UPLOADER_TYPE.UPLOAD
        ? configUrls.API.uploadContent
        : configUrls.API.uploadTikTokContent;
    const currentTimeStamp = new Date().valueOf();
    url = `${url}?access_time=${currentTimeStamp}`;
    if (data.selectId) {
      url = `${url}&selectId=${data.selectId}`;
    }
    if (data.challengeId) {
      url = `${url}&campaignId=${data.challengeId}`;
    }
    if (widgetId) {
      url = `${url}&widgetId=${widgetId}`;
    }

    return url;
  };

  const resetStoredData = () => {
    if (
      isCancelledProgressModal ||
      fileProgress.length ||
      uploadedContentList.length
    ) {
      dispatch(
        storeData({
          isCancelledProgressModal: false,
          fileProgress: [],
          uploadedContentList: [],
        })
      );
    }
  };

  const handleSubmitUpload = () => {
    const data = setPayloadData(listFields, uploadInfo);
    const timeout = timeoutDuration || DEFAULT_TIMEOUT;
    resetStoredData();

    if (!_.isEmpty(data)) {
      const { additionalFields = [] } = data;
      let { basicFields } = data;

      // Remove email opt in from the list of terms
      const filteredTerms = terms.filter((item) => !item.fieldName);
      const hasEntribeStandardTerms = filteredTerms.some(
        (item) => item.checked && item.isEntribeStandardTerms
      );
      const hasCustomTerms = filteredTerms.some(
        (item) => item.checked && !item.isEntribeStandardTerms
      );

      let payloadData = {
        widgetId,
        clientId,
        selectId,
        challengeId: campaign.id || 0,
        uploadedFiles,
        location: uploadLocation,
        timeout: utils.convertTimeout(timeout),
        uploadedFileType: uploadedFileType,
        termsIds: getTermsIds(terms),
        orgType,
        brandName,
        hasEntribeStandardTerms,
        hasCustomTerms,
        termsTemplateId,
      };

      if (locationCollectMethod !== LOCATION_COLLECT_METHOD.NO_COLLECT) {
        payloadData.ipStack =
          locationCollectMethod === LOCATION_COLLECT_METHOD.COLLECT_WITH_CONSENT
            ? uploadLocation
            : getIPStack(uploadLocation);
      }

      const mailingAddress = {
        street: uploadInfo[MAILING_ADDRESS_FIELD_NAME.STREET],
        state: uploadInfo[MAILING_ADDRESS_FIELD_NAME.STATE],
        city: uploadInfo[MAILING_ADDRESS_FIELD_NAME.CITY],
      };

      let uploadWidgetLink = detailWidget.url;
      uploadWidgetLink = utils.formatUploadWidgetLink(
        uploadWidgetLink,
        payloadData,
        socialUsernameFromURL
      );
      const url = generateURL(payloadData);

      basicFields = utils.removeEmptyValueObj(basicFields);
      basicFields = { ...basicFields, ...socialUsernameFromURL };

      if (tikTokUser && tikTokUser.username) {
        basicFields[REUSED_FIELD_NAME.tiktok] = tikTokUser.username;
      }

      if (youTubeAccount && youTubeAccount.username) {
        basicFields[REUSED_FIELD_NAME.youtube] = youTubeAccount.username;
      }

      if (instagramAccount && instagramAccount.username) {
        basicFields[REUSED_FIELD_NAME.instagram] = instagramAccount.username;
      }

      payloadData = {
        ...basicFields,
        ...payloadData,
        uploadWidgetLink,
        mailingAddress: utils.removeEmptyValueObj(mailingAddress),
        zipCode: uploadInfo?.zipCode,
      };

      if (additionalFields) {
        payloadData = {
          ...payloadData,
          ...formatCustomProperties(additionalFields),
        };
      }

      if (emailOptInType && emailOptInType !== EMAIL_OPT_IN.NOT_ADD_USER.name) {
        payloadData.creatorOptInEmail = {
          ...creatorOptInEmailValues(emailOptIn, terms),
          lastWidgetId: widgetId,
        };
      }

      payloadData = utils.parseUploadData(payloadData);
      handleUploader(payloadData, url);
    }
  };

  const successCallback = (resp) => {
    handleFormState(resp);
  };

  const failedCallback = (resp) => {
    const errorMsg = resp.error ? resp.error : errorMsgs["en"].uploadFailed;
    handleErrorMsg(errorMsg);
  };

  const handleUploader = (data, url) => {
    if (
      activeUploaderType === UPLOADER_TYPE.TIKTOK &&
      selectedVideoList.length
    ) {
      delete data.uploadedFileType;
      delete data.uploadedFiles;

      const shareUrls = selectedVideoList.map((video) =>
        encodeURI(video.share_url)
      );

      data = { ...data, shareUrls };
      requestAPI(
        "post",
        url,
        data,
        successCallback,
        failedCallback,
        undefined,
        undefined,
        true
      );
    } else if (activeUploaderType === UPLOADER_TYPE.GOOGLE_DRIVE) {
      const validList = googleDrive?.selectedMediaList.filter(
        (item) => _.isEmpty(item?.limitationError) || !item?.limitationError
      );
      if (validList.length) {
        setIsShowLoading(true);
        const requestPayload = {
          ...data,
          fileRequests: validList,
          accessToken: googleDrive?.accessToken,
        };
        dispatch(uploadGoogleDriveContentRequest(requestPayload));
      } else {
        handleErrorMsg(t("message.valid_file_attached"));
      }
    } else if (
      activeUploaderType === UPLOADER_TYPE.YOUTUBE &&
      selectedVideoList.length
    ) {
      setIsShowLoading(true);
      const listVideoIds = selectedVideoList.map((item) => item.id);
      const requestPayload = {
        ...data,
        listVideoIds,
      };
      dispatch(uploadYouTubeContentRequest(requestPayload));
    } else if (
      activeUploaderType === UPLOADER_TYPE.INSTAGRAM &&
      selectedVideoList.length
    ) {
      setIsShowLoading(true);
      const requestPayload = {
        ...data,
        fileRequests: selectedVideoList,
      };
      dispatch(uploadInstagramContentRequest(requestPayload));
    } else {
      setIsShowLoading(true);
      setUploadPayloadList(convertUploadPayloadToFormData(data));
      dispatch(createContentRequest({ ...data }));
    }
  };
  const convertUploadPayloadToFormData = (data) => {
    let formDataList = [];
    const startTime = new Date().valueOf();

    uploadedFiles.forEach(async (uploadedFile) => {
      const convertedData = {
        ...data,
        location: uploadLocation,
        file: uploadedFile,
        startTime,
      };
      if (uploadedFile?.type?.includes(MEDIA_TYPE.VIDEO)) {
        convertedData.videoDuration = await utils.getVideoDuration(
          uploadedFile
        );
      }
      const formData = utils.parseUploadFormData(convertedData);
      formDataList.push({ formData });
    });

    return formDataList;
  };

  const closeTermConditions = () => {
    setActiveTerms();

    // focus after closing term modal
    const terms = document.getElementById("terms");
    if (terms) {
      terms.setAttribute("tabindex", "0");
      terms.focus();
    }
  };

  const contextValues = {
    uploadedFiles,
    setUploadedFiles,
    handleErrorMsg,
  };

  return (
    <WidgetBodyContext.Provider value={contextValues}>
      <StyledForm
        initialValues={uploadInfo}
        onFinish={handleSubmitUpload}
        onValuesChange={onValuesChange}
        form={form}
      >
        {!isMobile ? renderDesktopBody() : renderMobileBody()}
      </StyledForm>

      {activeTerms && (
        <TermConditions
          isMobile={isMobile}
          onClose={closeTermConditions}
          terms={activeTerms}
          handleAgreeTerm={handleAgreeTerm}
          className={scriptTagClassName}
          widgetInfo={widgetInfo}
        />
      )}
    </WidgetBodyContext.Provider>
  );
};

WidgetBody.propTypes = {
  detailWidget: PropTypes.object,
  isMobile: PropTypes.bool,
  isMoreField: PropTypes.bool,
  setIsMoreField: PropTypes.func,
  globalProps: PropTypes.object,
  handleFormState: PropTypes.func,
};

export default WidgetBody;
